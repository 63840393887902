import { useContext, useEffect, useMemo, useRef } from 'react'
import type { EChartsType } from 'echarts/types/dist/shared'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'

import { initChart, updateChart } from './EquipmentWasteChart.utils'
import { Chart, ChartWrapper } from '../EquipmentWaste.styles'
import { useGetWasteData } from '../../../../queries/BalrogApi/BalrogApi'
import { VesselPageContext } from '../../../../contexts'
import { ChartStencil } from '../../../../commons'

const CHART_ID = 'svc-equipment-fuel-waste-chart'

type Props = {
  dateRange?: IDateRangeValue
  sisterVesselImoList: string
}

const EquipmentWasteChart = ({ dateRange, sisterVesselImoList }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const chartRef = useRef<EChartsType>()

  const getWasteData = useGetWasteData(sisterVesselImoList, dateRange)

  const chartHeight = useMemo(() => {
    if (!getWasteData.isSuccess) {
      return 440
    }
    const imoNos = new Set(getWasteData.data.map((metric) => metric.imo))
    return 120 + 40 * imoNos.size
  }, [getWasteData.isSuccess, imoNo])

  useEffect(() => {
    // IMO number changed, reset the chart
    chartRef.current?.clear()
    chartRef.current = initChart(CHART_ID)
  }, [imoNo])

  useEffect(() => {
    if (!getWasteData.isSuccess || !chartRef.current) return

    updateChart(getWasteData.data, chartRef.current)
  }, [getWasteData.isSuccess, dateRange, imoNo])

  const isDataFetched = getWasteData.isSuccess

  return (
    <>
      <ChartWrapper
        height={chartHeight}
        style={{ marginTop: isDataFetched ? '40px' : 0 }}
      >
        {!isDataFetched && (
          <ChartStencil chartType='area' minHeight={chartHeight} />
        )}
        <Chart id={CHART_ID} visible={isDataFetched} />
      </ChartWrapper>
    </>
  )
}

export default EquipmentWasteChart
