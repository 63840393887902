import React, { useContext, useEffect, useRef, useState } from 'react'
import type { EChartsType } from 'echarts/types/dist/shared'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'
import { McCheckbox } from '@maersk-global/mds-react-wrapper'

import { initChart, updateChart } from './OverallWasteChart.utils'
import { Chart, ChartWrapper, CheckboxWrapper } from '../EquipmentWaste.styles'
import {
  useGetEnergyMetrics,
  useGetWasteData,
} from '../../../../queries/BalrogApi/BalrogApi'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import { Selection } from '../../../../utils'
import { ChartStencil } from '../../../../commons'

const CHART_ID = 'svc-overall-fuel-waste-chart'

type Props = {
  dateRange?: IDateRangeValue
  sisterVesselImoList: string
}

const OverallWasteChart = ({ dateRange, sisterVesselImoList }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)
  const chartRef = useRef<EChartsType>()
  const [selection, setSelection] = useState(Selection.ALL)

  const getEnergyMetrics = useGetEnergyMetrics(sisterVesselImoList, dateRange)
  const getWasteData = useGetWasteData(sisterVesselImoList, dateRange)

  useEffect(() => {
    // IMO number changed, reset the chart
    chartRef.current?.clear()
    chartRef.current = initChart(CHART_ID)

    // Attach event listener to the chart to update the checkbox state
    chartRef.current?.on('legendselectchanged', (event: any) => {
      if (Object.values(event.selected).every((isSelected) => isSelected)) {
        setSelection(Selection.ALL)
      } else if (
        Object.values(event.selected).every((isSelected) => !isSelected)
      ) {
        setSelection(Selection.NONE)
      } else {
        setSelection(Selection.PARTIAL)
      }
    })

    // Reset the checkbox state
    setSelection(Selection.ALL)
  }, [imoNo])

  useEffect(() => {
    if (!getEnergyMetrics.isSuccess || !getWasteData.isSuccess) {
      // Wait for both queries to succeed
      return
    }

    updateChart(getEnergyMetrics.data, getWasteData.data, chartRef.current)
  }, [getEnergyMetrics.isSuccess, getWasteData.isSuccess, dateRange, imoNo])

  const handleCheckboxClick = (event: MouseEvent) => {
    if (!chartRef.current) return

    event.preventDefault()

    const selectedVessels = chartRef.current?.getOption()?.legend?.[0]?.selected
    const allTrue = Object.values(selectedVessels).every(
      (isSelected) => isSelected,
    )

    chartRef.current?.dispatchAction({
      type: allTrue ? 'legendInverseSelect' : 'legendAllSelect',
    })

    if (allTrue) {
      setSelection(Selection.NONE)
    } else {
      setSelection(Selection.ALL)
    }
  }

  const isDataFetched = getEnergyMetrics.isSuccess && getWasteData.isSuccess

  return (
    <>
      <CheckboxWrapper>
        <McCheckbox
          fit={windowSize}
          label={'Select all vessels'}
          checked={selection !== Selection.NONE}
          indeterminate={selection === Selection.PARTIAL}
          click={handleCheckboxClick}
        />
      </CheckboxWrapper>
      <ChartWrapper height={416}>
        {!isDataFetched && <ChartStencil chartType='line' minHeight={416} />}
        <Chart id={CHART_ID} visible={isDataFetched} />
      </ChartWrapper>
    </>
  )
}

export default OverallWasteChart
