import styled from 'styled-components'

import { PadContent } from '../../../layout/styles'

export const Content = styled(PadContent)`
  position: relative;

  > mc-popover {
    position: absolute;
    right: 0;
  }
`

export const ChartWrapper = styled.div<{
  height: number
}>`
  position: relative;
  height: ${({ height }) => height}px;
  margin-bottom: 48px;
`

export const Chart = styled.div<{
  visible: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
