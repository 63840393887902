import { useContext, useEffect, useRef, useState } from 'react'
import type { EChartsType } from 'echarts/types/dist/shared'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'
import { McCheckbox } from '@maersk-global/mds-react-wrapper'

import { initChart, updateChart } from './LoadProfileChart.utils'
import {
  Chart,
  ChartWrapper,
  CheckboxWrapper,
} from '../../EquipmentWaste.styles'
import { useGetLoadProfile } from '../../../../../queries/BalrogApi/BalrogApi'
import { Selection } from '../../../../../utils'
import { VesselPageContext, WindowContext } from '../../../../../contexts'
import { ChartStencil } from '../../../../../commons'

const CHART_ID = 'svc-me-class-load-profile-chart'

type Props = {
  sisterVesselImoList: string
  dateRange?: IDateRangeValue
}

const LoadProfileChart = ({ sisterVesselImoList, dateRange }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)
  const chartRef = useRef<EChartsType>()
  const [selection, setSelection] = useState(Selection.ALL)

  const getLoadProfile = useGetLoadProfile(sisterVesselImoList, dateRange)

  useEffect(() => {
    // IMO number changed, reset the chart
    chartRef.current?.clear()
    chartRef.current = initChart(CHART_ID)

    // Attach event listener to the chart to update the checkbox state
    chartRef.current?.on('legendselectchanged', (event: any) => {
      if (Object.values(event.selected).every((isSelected) => isSelected)) {
        setSelection(Selection.ALL)
      } else if (
        Object.values(event.selected).every((isSelected) => !isSelected)
      ) {
        setSelection(Selection.NONE)
      } else {
        setSelection(Selection.PARTIAL)
      }
    })

    // Reset the checkbox state
    setSelection(Selection.ALL)
  }, [imoNo])

  useEffect(() => {
    if (!chartRef.current || !getLoadProfile.isSuccess) return

    updateChart(getLoadProfile.data, chartRef.current)
  }, [getLoadProfile.isSuccess, dateRange, imoNo])

  const handleCheckboxClick = (event: MouseEvent) => {
    if (!chartRef.current) return

    event.preventDefault()

    const selectedVessels = chartRef.current?.getOption()?.legend?.[0]?.selected
    const allTrue = Object.values(selectedVessels).every((value) => value)

    chartRef.current?.dispatchAction({
      type: allTrue ? 'legendInverseSelect' : 'legendAllSelect',
    })

    if (allTrue) {
      setSelection(Selection.NONE)
    } else {
      setSelection(Selection.ALL)
    }
  }

  const isDataFetched = getLoadProfile.isSuccess

  return (
    <>
      <CheckboxWrapper>
        <McCheckbox
          fit={windowSize}
          label={'Select all vessels'}
          checked={selection !== Selection.NONE}
          indeterminate={selection === Selection.PARTIAL}
          click={handleCheckboxClick}
        />
      </CheckboxWrapper>
      <ChartWrapper height={480} style={{ marginBottom: 0 }}>
        {!isDataFetched && <ChartStencil chartType='line' minHeight={480} />}
        <Chart id={CHART_ID} visible={isDataFetched} />
      </ChartWrapper>
    </>
  )
}

export default LoadProfileChart
