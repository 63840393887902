import * as echarts from 'echarts'
import * as moment from 'moment'

import { Chart, YDomain, YDomains } from './models'
import {
  getClosestTenMinute,
  getDateRangeFilterDifference,
} from '../utils/time-utils'
import { getXPercentageOfNumber } from '../utils/math-utils'
import { type QueryPeriod } from '../features/filter'

/**
 * A method that generates a tooltip for a single curve
 *
 * @param values values to display
 * @param headers headers to display
 * @param textAligns text alignments for the headers
 */
export const singleCurveTooltip = (
  values: Array<{
    [key: string]: string | number
  }>,
  headers?: Array<{
    [key: string]: string | number
  }>,
  textAligns?: { [key: string]: string },
): string => {
  return `<table class="tooltip">
      <thead>
        ${
          !headers
            ? ''
            : headers
                .map((row) => {
                  return `<tr class="header-bottom">${Object.keys(row)
                    .map((key) => {
                      if (textAligns && textAligns[key]) {
                        return `<th style="text-align:${textAligns[key]}">${row[key]}</th>`
                      } else {
                        return `<th>${row[key]}</th>`
                      }
                    })
                    .join('')}</tr>`
                })
                .join('')
        }
      </thead>
      <tbody>
        ${values
          .map(
            (row) => `
          <tr>
            ${Object.keys(row)
              .map((key) => {
                if (textAligns && textAligns[key]) {
                  return `<td style="text-align:${textAligns[key]}">${row[key]}</td>`
                } else {
                  return `<td>${row[key]}</td>`
                }
              })
              .join('')}
          </tr>
          `,
          )
          .join('')}
      </tbody>
    </table>
    `
}

/**
 *  A method that make certain that min and max value is no less than the min diff value
 *
 * @param domain the y domain to modify
 * @param minDiff minimum diff that can be zoomed
 */
export const lineYMinDomain = (domain: YDomain, minDiff: number): YDomain => {
  let maxValue = domain.max ? domain.max : 0
  let minValue = domain.min ? domain.min : 0
  const diff = maxValue - minValue
  if (diff < minDiff) {
    const padding = (minDiff - diff) / 2
    return { max: maxValue + padding, min: minValue - padding }
  }
  return { max: maxValue, min: minValue }
}

/**
 *  A method that make certain that min and max value is no less than the min diff value
 *
 * @param domain the y domain to modify
 * @param minDiff minimum diff that can be zoomed
 */
export const areaYMinDomain = (domain: YDomain, minDiff: number): YDomain => {
  let maxValue = domain.max ? domain.max : 0
  let minValue = domain.min ? domain.min : 0
  const diff = maxValue - minValue
  if (diff < minDiff) {
    const padding = minDiff - diff
    return { max: maxValue + padding, min: minValue }
  }
  return { max: maxValue, min: minValue }
}

/**
 * Adding padding to an area graph
 *
 * @param domain Domain to add padding to
 */
export const areaGraphPadding = (domain: YDomain): YDomain => {
  let maxValue = domain.max ? domain.max : 0
  let minValue = domain.min ? domain.min : 0
  const diff = maxValue - minValue
  const padding = getXPercentageOfNumber(diff, 10)
  return { max: maxValue + padding, min: minValue }
}

/**
 * Adding padding to a line graph
 *
 * @param domain Domain to add padding to
 */
export const lineGraphPadding = (domain: YDomain): YDomain => {
  let maxValue = domain.max ? domain.max : 0
  let minValue = domain.min ? domain.min : 0
  const diff = maxValue - minValue
  const padding = getXPercentageOfNumber(diff, 10)
  return { max: maxValue + padding, min: minValue - padding }
}

export const calculateYDomainAverages = (
  startValue: number,
  endValue: number,
  dataSet: {
    timestamps: number[]
    [values: string]: (number | null)[]
  },
): YDomains => {
  const { timestamps, ...values } = dataSet
  const [start, end] = findStartAndEndIndex(timestamps, startValue, endValue)
  const yDomains = Object.keys(values).reduce((domains, key) => {
    const series =
      values[key] !== null
        ? (values[key]
            .slice(start, end + 1)
            .filter((number) => number !== null) as number[])
        : []
    if (series.length > 0) {
      const seriesMin = Math.min(...series)
      const seriesMax = Math.max(...series)
      domains[key] = {
        min: seriesMin,
        max: seriesMax,
      }
    } else {
      domains[key] = {
        min: 0,
        max: 0,
      }
    }
    return domains
  }, {} as YDomains)

  return Object.keys(yDomains).reduce((averages, key) => {
    const { max, min } = yDomains[key]
    const average = (max + min) / 2
    const diff = Math.max(max - average, average - min)
    averages[key] = {
      min: average - diff,
      max: average + diff,
    }
    return averages
  }, {} as YDomains)
}

export const calculateYDomainExtremes = (
  startValue: number,
  endValue: number,
  dataSet: {
    timestamps: number[]
    [values: string]: (number | null)[]
  },
): YDomain => {
  const { timestamps, ...values } = dataSet
  const [start, end] = findStartAndEndIndex(timestamps, startValue, endValue)
  return Object.keys(values).reduce(
    (domain, key) => {
      const series = values[key]
        .slice(start, end + 1)
        .filter((number) => number !== null) as number[]
      if (series.length > 0) {
        const seriesMin = Math.min(...series)
        const seriesMax = Math.max(...series)
        return {
          min: Math.min(domain.min, seriesMin),
          max: Math.max(domain.max, seriesMax),
        }
      }
      return { min: domain.min, max: domain.max }
    },
    { min: Infinity, max: -Infinity },
  )
}

/**
 * As the Automatic Data Collector might be down,
 * we can't guarantee that timestamp along the x-axis actually exists in the data set.
 * For this specific case we then return the timestamp before.
 * This method returns the index of that timestamp
 *
 * @param timestamps Series of timestamps
 * @param timestamp Timestamp to find appropriate index to
 */
export const findTimestampIndex = (
  timestamps: number[],
  timestamp: number,
): number => {
  const value = getClosestTenMinute(moment.utc(timestamp)).valueOf()
  for (let i = 0; i < timestamps.length; i++) {
    const current = getClosestTenMinute(moment.utc(timestamps[i])).valueOf()
    const last = timestamps[i - 1]
      ? getClosestTenMinute(moment.utc(timestamps[i - 1])).valueOf()
      : getClosestTenMinute(moment.utc(timestamps[0])).valueOf()
    if (i === 0 && value < current) {
      return 0
    } else if (i === timestamps.length - 1 && value > current) {
      return timestamps.length - 1
    } else if (value === current) {
      return i
    } else if (last < value && value < current) {
      return i - 1
    }
  }
  return 0
}

export const findStartAndEndIndex = (
  timestamps: number[],
  start: number,
  end: number,
): number[] => [
  findTimestampIndex(timestamps, start),
  findTimestampIndex(timestamps, end),
]

export const getDataIndex = (
  params:
    | echarts.EChartOption.Tooltip.Format
    | echarts.EChartOption.Tooltip.Format[],
): number | undefined => {
  if (params instanceof Array && params.length > 0) {
    return params[0].dataIndex
  } else if (!(params instanceof Array) && params.dataIndex) {
    return params.dataIndex
  } else {
    return
  }
}

export function mapFilterLegend(dateRangeFilter: QueryPeriod) {
  if (getDateRangeFilterDifference(dateRangeFilter, 'd') === 0) {
    const hourDiff = getDateRangeFilterDifference(dateRangeFilter, 'h')
    const minDiff =
      getDateRangeFilterDifference(dateRangeFilter, 'minutes') - hourDiff * 60
    if (hourDiff > 0) {
      return minDiff > 0
        ? `Last ${hourDiff}h ${minDiff}m`
        : `Last ${hourDiff} hours`
    }
    return `Last ${minDiff} minutes`
  }
  return `${moment.utc(dateRangeFilter.from).format('DD MMM')} - ${moment
    .utc(dateRangeFilter.to)
    .format('DD MMM')}`
}

export const tooltip = (
  timestamp: number | string,
  values: Array<{
    [key: string]: string | number
  }>,
  headers?: Array<{
    [key: string]: string | number
  }>,
  textAligns?: { [key: string]: string },
): string => {
  const columns = Math.max(...values.map((row) => Object.keys(row).length))

  return `<table class="tooltip">
      <thead>
        <tr>
          <th colspan="${columns}">${
    timestamp
      ? moment.utc(timestamp).format('DD MMM HH:mm UTC')
      : '-- --- --:-- UTC'
  }</th>
        </tr>
        ${
          !headers
            ? ''
            : headers
                .map((row) => {
                  return `<tr class="header-bottom">${Object.keys(row)
                    .map((key) => {
                      if (textAligns && textAligns[key]) {
                        return `<th style="text-align:${textAligns[key]}">${row[key]}</th>`
                      } else {
                        return `<th>${row[key]}</th>`
                      }
                    })
                    .join('')}</tr>`
                })
                .join('')
        }
      </thead>
      <tbody>
        ${values
          .map(
            (row) => `
          <tr>
            ${Object.keys(row)
              .map((key) => {
                if (textAligns && textAligns[key]) {
                  return `<td style="text-align:${textAligns[key]}">${row[key]}</td>`
                } else {
                  return `<td>${row[key]}</td>`
                }
              })
              .join('')}
          </tr>
          `,
          )
          .join('')}
      </tbody>
    </table>
    `
}

export function connectChartsX(chartList: Chart[]) {
  chartList.forEach((from) => {
    chartList.forEach((to) => {
      if (from === to) {
        return
      }
      from.on('updateAxisPointer', (params: any) => {
        const action = (to as any).makeActionFromEvent(params)
        to.dispatchAction({ ...action, tooltipOption: { show: false } }, true)
      })
    })
  })
}

/**
 * Displacement of data values to spread clustered data if the difference between consecutive values
 * is below a certain threshold.
 *
 * @param data - The data array to which the displacement should be applied to.
 * @returns The modified data array with the displacement applied.
 */
export const displace = (data: Array<{ value: number; name: string }>) => {
  if (data.length < 2) return data

  // Create a deep copy of the data array to prevent mutation of the original data.
  const _data = data
    .map((item) => ({ ...item }))
    .sort((a, b) => a.value - b.value)

  const range = _data[_data.length - 1].value - _data[0].value
  const threshold = range * 0.1
  const centerIndex = Math.floor(_data.length / 2)
  const jitter = range * 0.1

  _data.forEach((item, index) => {
    if (index < centerIndex) {
      if (_data[index + 1].value - item.value < threshold) {
        item.value -= jitter
      }
    } else if (index > centerIndex) {
      if (item.value - _data[index - 1].value < threshold) {
        item.value += jitter
      }
    }
  })

  return _data
}
